import React from 'react'
import {lazy, mount, route} from 'navi'

import ReportPage from "./pages/report/report";
import Books from "./pages/books/books";
import Test from "./pages/test/test";
import Analysis from "./pages/analysis/analysis";

// Define routes using mount(), route(), and other middleware.
const routes =
    mount({
        '/': route(() => ({ view: <ReportPage />})),
        '/test': route(() => ({ view: <Test />})),
        '/test/analysis': route(() => ({ view: <Analysis />})),
        '/analysis': route(() => ({ view: <Analysis />})),
        '/books': route(() => ({ view: <Books />})),
        // '/test': lazy(() => import('./pages/test/test-routes')),
        // '/analysis': lazy(() => import('./pages/analysis/analysis-routes')),
    })

export default routes





// import { lazy, redirect  } from 'navi';
// // import Home from "./pages/page-not-found";
//
// // export default mount({
// //     '/': redirect('/'),
// //     '/about': redirect('/'),
// //     //'/404': lazy(() => import('./pages/page-not-found/page-not-found-routes')),
// //
// // });
//
//
// // const Home = lazy(() => import('./pages/Home'));
// const PageNotFound = lazy(() => import('./pages/page-not-found/page-not-found-routes'));
//
// const routes = [
//     // {
//     //     path: '/',
//     //     title: 'Home',
//     //     view: Home,
//     // },
//     {
//         path: '/page-not-found',
//         title: 'Page Not Found',
//         view: PageNotFound,
//     },
// ];
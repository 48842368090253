import React, {ReactElement, useEffect, useState} from "react"
import ReportApi from '../../api/report/report/report-api'
import axios from "axios";
import qs from "qs";
import {Section} from "../../components/layout/section/section";


const API_KEY = '8vGWPdZmL5gPC0gu0prG9sNMCu9fZV4n';
const USER_NAME = 'betezion';

const reportApi = axios.create({
    baseURL: 'https://prod-api.aws.sheepgenetics.org.au/report/api',
    //baseURL: 'https://www.api.betezion.com',
    headers: {
        'API-KEY': API_KEY,
        'USER-NAME': USER_NAME,
    },
    paramsSerializer: (params) => qs.stringify(params, { arrayFormat: 'repeat' }),
});

interface analysisResponse {
    analysisName: string;
    analysisId: number;
}

const Test = (): ReactElement => {

    return (
        <div>
            <h2>Test page</h2>
            <p>This is test page</p>
            <p>More about <a href="/test/analysis">Analysis</a>...</p>
        </div>
    )
}

export default Test;
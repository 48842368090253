import React, {ReactElement, useEffect, useState} from "react"
import ReportApi from '../../api/report/report/report-api'
import axios from "axios";
import qs from "qs";
import {Section} from "../../components/layout/section/section";

// export types analysisResponse = {
//     analysisDate: string;
//     analysisId: number;
//     analysisName: string;
//     masterAnalysisId: string;
//     percentileDropYear:number;
// };
//
// const Test = (): ReactElement => {
//     const  [percentiles, setPercentiles] = useState<analysisResponse[] | null>();
//     useEffect(() => {
//         const url = 'https://prod-api.aws.sheepgenetics.org.au/report/api/analysis/all'
//         axios.get(url).then((response) => {
//             // console.log(response.data)
//             setPercentiles(response.data);
//         })
//     }, []);
//     return (
//         <div>
//             <p>Test result goes here:</p>
//             {percentiles ? percentiles.map((percent) => {
//                 return <p>{percent.analysisName + '=' + percent.analysisId }</p>
//             }) : null}
//         </div>
//     )
// }
// export default Test

const API_KEY = '8vGWPdZmL5gPC0gu0prG9sNMCu9fZV4n';
const USER_NAME = 'betezion';

const reportApi = axios.create({
    baseURL: 'https://prod-api.aws.sheepgenetics.org.au/report/api',
    headers: {
        'API-KEY': API_KEY,
        'USER-NAME': USER_NAME,
    },
    paramsSerializer: (params) => qs.stringify(params, { arrayFormat: 'repeat' }),
});

interface analysisResponse {
    analysisName: string;
    analysisId: number;
}

const Report = (): ReactElement => {
    const [analysisList, setAnalysisList] = useState<analysisResponse[] | null>();
    const [isLoading, setIsLoading] = useState(false);
    const [error, setError] = useState("");

    /*
    // const getAllAnalysis = async () => {
    //     const response = await reportApi.get('/analysis/all');
    //     return response.data as [];
    // };
    //
    // useEffect(() => {
    //     const getAnalysis = async () => {
    //         const allAnalysis = await getAllAnalysis();
    //         setPercentiles(allAnalysis);
    //     };
    //
    // }, []);

    // async function fetchAnalysisList() {
    //     try {
    //         const response = await reportApi.get('/analysis/all');
    //         console.log(response.data);
    //         setAnalysisList(response.data);
    //     } catch (error) {
    //         console.error(error);
    //     }
    // }
    //
    // useEffect(() => {
    //     fetchAnalysisList();
    // }, []);
    */

    useEffect(() => {
        const fetchAnalysisList = async () => {
            setIsLoading(true);
            try {
                const response = await reportApi.get('/analysis/all');
                console.log(response.data);
                setAnalysisList(response.data);
            } catch (error) {
                setError("Error fetching analysis data!!");
                console.error(error);
            } finally {
                setIsLoading(false);
            }
        }
        fetchAnalysisList();
    }, []);

    // useEffect(() => {
    //     reportApi.get('/analysis/all').then((response) => {
    //     //myAxios.get('/products/v2/book').then((response) => {
    //         console.log(response.data)
    //         setPercentiles(response.data);
    //     }).catch(error => {
    //         console.error(error);
    //     });
    // }, []);

    return (
        <div>
            <Section title={"Different title"}>Thi is my section</Section>
            <p>Report result goes here:</p>
            {analysisList ? analysisList.map((analysis) => {
                return <p>{analysis.analysisName + '=' + analysis.analysisId }</p>
            }) : null}
        </div>
    )
}

export default Report;
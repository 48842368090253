import { useEffect, useState } from "react";
import { useLocation, useParams } from "react-router-dom";
import { Book } from "../../types/appType";
import { fetchBookData } from "../../services/book-services";


export const useBookData = () => {
    const [bookData, setBookData] = useState<Book[] | null>(null);
    const [isLoading, setIsLoading] = useState(false);
    const [error, setError] = useState("");

    useEffect(() => {
        const getBookData = async () => {
            setIsLoading(true);
            try {
                const response = await fetchBookData.get('/');
                setBookData(response.data);
            } catch (error) {
                setError("Error fetching analysis data!!");
            } finally {
                setIsLoading(false);
            }
        };
        getBookData();
    }, []);

    return { bookData, isLoading, error };
};

export default useBookData;


// export const useBookData = (bookId: number) => {
//     const [bookData, setBookData] = useState<Book | null>(null);
//     const [isLoading, setIsLoading] = useState(false);
//     const [error, setError] = useState("");
//
//     useEffect(() => {
//         const getBookData = async () => {
//             setIsLoading(true);
//             try {
//                 const myData = await fetchBookData(bookId);
//                 setBookData(myData);
//             } catch (error) {
//                 setError("Error fetching book data");
//             } finally {
//                 setIsLoading(false);
//             }
//         };
//         getBookData();
//     }, [bookId]);
//
//     return { bookData, isLoading, error };
// };
//
// export default useBookData;

import React, {useEffect, useState} from 'react';
import Header from "../layout/header/header";
import {Section} from "../layout/section/section";
import Footer from "../layout/footer/footer";
import Report from "../../pages/report/report";
import routes from '../../index.routes';
import axios from "axios";
import {Router, View} from "react-navi";
import './app.scss'

export type Percentile = {
    analysisDate: string;
    analysisId: number;
    analysisName: string;
    masterAnalysisId: string;
    percentileDropYear:number;
};

function App() {
    // const  [percentiles, setPercentiles] = useState<Percentile[] | null>();
    // useEffect(() => {
    //     const url = 'https://prod-api.aws.sheepgenetics.org.au/report/api/analysis/all'
    //     axios.get(url).then((response) => {
    //         // console.log(response.data)
    //         setPercentiles(response.data);
    //     })
    // }, []);
  return (
      <Router
          routes={routes}
      >
        <>
            <div className="my-app" id="app">
                <Header title={"My Header"}/>
                <main className="main">
                    <View />
                </main>
                <Footer/>
            </div>
        </>
      </Router>
  );
}

export default App;

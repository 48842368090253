/*
// import axios from 'axios';
// import { useState } from 'react';
//
// const API_KEY = '8vGWPdZmL5gPC0gu0prG9sNMCu9fZV4n';
// const USER_NAME = 'betezion';
//
// const api = axios.create({
//     baseURL: 'https://www.api.betezion.com',
//     headers: {
//         'API-KEY': API_KEY,
//         'USER-NAME': USER_NAME,
//     },
// });
//
// interface Book {
//     id: number;
//     title: string;
//     author: string;
// }
//
// const BookList = () => {
//     const [book, setBooks] = useState<Book[]>([]);
//
//     const fetchBooks = async () => {
//         try {
//             const response = await api.get('/book');
//             setBooks(response.data);
//         } catch (error) {
//             console.error(error);
//         }
//     };
//
//     const addBook = async (book: Book) => {
//         try {
//             const response = await api.post('/book', book);
//             setBooks((prevBooks) => [...prevBooks, response.data]);
//         } catch (error) {
//             console.error(error);
//         }
//     };
//
//     const deleteBook = async (id: number) => {
//         try {
//             await api.delete(`/book/${id}`);
//             setBooks((prevBooks) => prevBooks.filter((book) => book.id !== id));
//         } catch (error) {
//             console.error(error);
//         }
//     };
//
//     return (
//         <div>
//             <h1>Book List</h1>
//             <ul>
//                 {book.map((book) => (
//                     <li key={book.id}>
//                         {book.title} by {book.author}
//                         <button onClick={() => deleteBook(book.id)}>Delete</button>
//                     </li>
//                 ))}
//             </ul>
//             <form onSubmit={(event) => {
//                 event.preventDefault();
//                 const formData = new FormData(event.target as HTMLFormElement);
//                 const newBook: Book = {
//                     id: book.length + 1,
//                     title: formData.get('title') as string,
//                     author: formData.get('author') as string,
//                 };
//                 addBook(newBook);
//             }}>
//                 <input type="text" name="title" placeholder="Title" required />
//                 <input type="text" name="author" placeholder="Author" required />
//                 <button type="submit">Add Book</button>
//             </form>
//             <button onClick={fetchBooks}>Refresh</button>
//         </div>
//     );
// };
//
// export default BookList;
*/

import React, { FC } from "react";
import { useParams } from "react-router-dom";
import { useBookData } from "../../api/book/book-api";

type Params = {
    bookId: string,
};

const BookPage: FC = () => {
    // const { bookId } = useParams<Params>();
    // console.log({bookId}); // this is not returning value ?????
    const { bookData, isLoading, error } = useBookData();
    console.log(bookData);

    if (isLoading) {
        return <div>Loading...</div>;
    }

    if (error) {
        return <div>{error}</div>;
    }

    if (!bookData) {
        return null;
    }

    return (
        <>
            <div>
                <p>Report result goes here:</p>
                {bookData ? bookData.map((book) => {
                    return <p>{book.title + '...' + book.author }</p>
                }) : null}
            </div>
        </>
    );
};

export default BookPage;


/*
<h1>{book.title}</h1>
<div>
    <img src={book.imageUrl} alt={`Cover of ${book.title}`} />
    <p>{book.description}</p>
</div>
<div>
    <h2>Details</h2>
    <ul>
        <li>Author: {book.author}</li>
        <li>Publisher: {book.publisher}</li>
        <li>Publication date: {book.publicationDate}</li>
        <li>Price: ${book.price.toFixed(2)}</li>
    </ul>
</div>
 */
import axios from "axios";
import { Analysis } from "../types/appType";
import { getApiEndpoint } from "../utils/apiUtiles";
import qs from "qs";
import { API_KEY, USER_NAME } from "../utils/constants"

export const endpoint = getApiEndpoint(`/`);

export const fetchAnalysisData = axios.create({
    baseURL: endpoint,
    headers: {
        'API-KEY': API_KEY,
        'USER-NAME': USER_NAME,
    },
    paramsSerializer: (params) => qs.stringify(params, { arrayFormat: 'repeat' }),
});


import { ReactElement } from "react"
import "./header.scss"
import {Link} from "react-navi";
// import { AppBar, Button, Container, Link, Toolbar } from '@material-ui/core';
import { ReactComponent as MYlogo } from '../../../assets/images/logo.svg';

type HeadingProps = { title: string }

const Header = ({ title }: HeadingProps): ReactElement => {
    return (
        <>
        <header className="header">
            <Link
                className="logo__link"
                href="/"
            >
                <MYlogo />
            </Link>
            <nav>
                <ul>
                    <li>
                        <Link exact href="/" activeClassName="active">
                            Home
                        </Link>
                    </li>
                    <li>
                        <Link href="/test" activeClassName="active">
                            Test
                        </Link>
                    </li>
                    <li>
                        <Link href="/analysis" activeClassName="active">
                            Analysis
                        </Link>
                    </li>
                    <li>
                        <Link href="/books" activeClassName="active">
                            Books
                        </Link>
                    </li>
                </ul>
            </nav>
        </header>
    </>
    )
}
export default Header
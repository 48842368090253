import axios, { AxiosRequestConfig } from 'axios';
import { Book } from "../types/appType";
import {getApiEndpoint, getApiEndpointBZ} from "../utils/apiUtiles";
import { API_KEY, USER_NAME } from "../utils/constants"
import qs from "qs";


export const endpoint = getApiEndpointBZ(`/books`);

export const fetchBookData = axios.create({
    baseURL: endpoint,
    headers: {
        'Accept': 'application/json',
        'API-KEY': API_KEY,
        'USER-NAME': USER_NAME,
        'Access-Control-Allow-Origin': '*',
        'Access-Control-Allow-Methods': 'GET,PUT,POST,DELETE,PATCH,OPTIONS',
    },
    paramsSerializer: (params) => qs.stringify(params, { arrayFormat: 'repeat' }),
    maxRedirects: 5, // Set the maximum number of redirects to follow (adjust as needed)
});

// export const fetchBookData = axios.create({
//     baseURL: endpoint,
//     headers: {
//         'Accept': 'application/json',
//         'API-KEY': API_KEY,
//         'USER-NAME': USER_NAME,
//     },
//     paramsSerializer: (params) => qs.stringify(params, { arrayFormat: 'repeat' }),
// });

// fetchBookData.interceptors.request.use((config) => {
//         config.withCredentials = true;
//         // if (config.headers) {
//         //     config.headers['Access-Control-Allow-Origin'] = '*';
//         //     config.headers['Access-Control-Allow-Methods'] = 'GET, POST, PUT, DELETE';
//         //     config.headers['Access-Control-Allow-Headers'] = 'Content-Type, Authorization';
//         // }
//
//         return config;
//     }
// );

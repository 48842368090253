import { ReactElement } from "react"
import "./footer.scss"

// types TestProps = { footer: string }

const Footer = (): ReactElement => {
    return (
        <footer className="footer">
            <div className="content">Test | &copy; copyright 2023</div>
        </footer>
    )
}
export default Footer
import { useEffect, useState } from "react";
import { Analysis } from "../../types/appType";
import { fetchAnalysisData } from "../../services/analysis-service";



export const useAnalysisData = () => {
    const [analysisData, setAnalysisData] = useState<Analysis[] | null>(null);
    const [isLoading, setIsLoading] = useState(false);
    const [error, setError] = useState("");

    useEffect(() => {
        const getAnalysisData = async () => {
            setIsLoading(true);
            try {
                const response = await fetchAnalysisData.get('/analysis/all');
                setAnalysisData(response.data);
            } catch (error) {
                setError("Error fetching analysis data!!");
            } finally {
                setIsLoading(false);
            }
        };
        getAnalysisData();
    }, []);

    return { analysisData, isLoading, error };
};

export default useAnalysisData;
